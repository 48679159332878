import React from "react";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import {
	Link,
	Text,
	VerticalList,
	CloudinaryImage,
	Button,
	Card,
	Grid,
	Col,
	HorizontalList,
	Separator,
	StarRatingWithLogo,
} from "@website-builder/ui/shared/elements";
import { SimplifiedListWrapper, ListItemWrapper } from "./style";
import { LEFT_CONTENT } from "./constants";
import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const SimplifiedList = (props) => {
	const {
		eyebrowLabel,
		leftSection,
		listItems,
		cta,
		title,
		description,
		listHeader,
		listBulletColor,
		listBulletType,
		customIconStrokeColor,
		customIconFillColor,
		globalVariableWithURL,
		logoSection,
		pageSection,
		bottomDescription,
		sectionName,
		isDarkBg,
		starRatingWithLogo,
	} = props;
	const isTwoColumnSectionContent = sectionName === "TwoColumnSection";
	const BUSINESS_VERTICAL = process.env.GATSBY_BUSINESS_VERTICAL;
	const isB2U = BUSINESS_VERTICAL === "b2u";
	const checkMarkIconConfig = {
		iconName: "checkCircle",
		stroke: listBulletColor ? listBulletColor : "var(--primary-forest-400)",
		size: "1.5rem",
	};
	//This is to create the left section containing text and description
	const createTextDescription = (item) => {
		const { title, description, link, eyebrowLabel } = item || {};
		const linkObject = link?.length ? link[0] : {};
		const { text = "", link: linkUrl = {} } = linkObject || {};
		const richTextDescription = richTextResolver(
			description,
			globalVariableWithURL,
		);
		return (
			<div>
				{eyebrowLabel ? (
					<div className="eyebrow-label left">
						<Text dark={isDarkBg} variant="label_M">
							{eyebrowLabel}
						</Text>
					</div>
				) : null}
				{title ? (
					<Text
						dark={isDarkBg}
						className="header-text"
						variant="headline_3"
						tag="h2"
					>
						{title}
					</Text>
				) : null}
				{description ? (
					<Text
						dark={isDarkBg}
						className="description-text rich-text-wrapper"
						tag="div"
						variant="paragraph_L"
						dangerouslySetInnerHTML={{ __html: richTextDescription }}
					/>
				) : null}
				{text ? (
					<Link
						href={linkObject?.link?.link}
						target={linkObject?.link?.target}
						arrowCta
						linkText={text}
						onClick={(e) =>
							handleCTAClick(
								{
									type: "internal",
									event: BUTTON_INTERACTION.LINK_CLICK,
									...linkObject,
								},
								{
									page_section: pageSection,
									click_text: linkObject?.text,
									redirect_to: linkObject?.link?.link,
								},
								{},
								e,
							)
						}
					/>
				) : null}
			</div>
		);
	};
	//This is to create the left section containing image content
	const createImageContent = (item) => (
		<CloudinaryImage
			className="simplified-list-image"
			url={item?.image?.image || item?.imageUrl}
			alt={item?.image?.altTag}
			quality="auto"
			fetchFormat="auto"
			lazyload
		></CloudinaryImage>
	);
	//This is to create the brand bar on the bottom of right section
	const getLogoSection = (brandBarData) => {
		const logoItems = brandBarData?.brandLogos?.map((item, index) => (
			<CloudinaryImage
				key={`logo-image-${index}`}
				url={item?.image?.image}
				className="logo-section-image"
				quality="auto"
				lazyload
				fetchFormat="auto"
				alt={item?.image?.altTag}
			/>
		));
		return (
			<>
				{brandBarData?.hasSeparatorOnTop && (
					<Separator className="logo-section-seperator" />
				)}
				<HorizontalList
					className="logo-list"
					listItems={logoItems}
					maxItems={brandBarData?.maxLogosInRow}
					labelConfig={{
						text: brandBarData?.labelText,
						color: "var(--secondary-gray-dark)",
					}}
					gap="24px"
				/>
			</>
		);
	};
	//This is to create the left section containing price card
	const createPriceCard = (item) => {
		const { price, header, description: desc, cta, bgColor } = item || {};
		const ctaObject = cta?.length ? cta[0] : {};
		const { buttonText, link = {}, type, darkBg, disabled } = ctaObject || {};
		const description = richTextResolver(desc, globalVariableWithURL);
		return (
			<Card
				className="card-wrapper simplified-list-price-card"
				color={bgColor?.color}
			>
				{header && (
					<Text dark={isDarkBg} variant="headline_6">
						{header}
					</Text>
				)}
				{price && (
					<Text
						dark={isDarkBg}
						variant="headline_5"
						tag="p"
						className="price"
						color={{ base: "primary", color: "forest", shade: "400" }}
					>
						{price}
					</Text>
				)}
				{description && (
					<Text dark={isDarkBg} variant="paragraph_S">
						<div
							className="description rich-text-wrapper"
							dangerouslySetInnerHTML={{ __html: description }}
						></div>
					</Text>
				)}
				{link?.link ? (
					<Button
						{...ctaObject}
						onClick={() =>
							handleCTAClick(ctaObject, {
								page_section: pageSection,
								click_text: ctaObject?.buttonText,
								redirect_to: ctaObject?.link?.link,
							})
						}
						className="price-card-button"
						style={{ fontWeight: "var(--desktop-body-s-bold-fw)" }}
						type={type}
						darkBg={darkBg}
						disabled={disabled}
						buttonText={buttonText}
					/>
				) : null}
			</Card>
		);
	};
	//Create custom bullet list of image or icons
	const createCustomBulletList = () =>
		listItems.map(({ customBullet: [bullet] = [] }) => {
			if (bullet?.iconName) {
				return {
					...bullet,
					type: "icon",
					stroke: customIconStrokeColor,
					fill: customIconFillColor,
					size:
						(bullet?.iconName &&
							(bullet?.size ? `${bullet.size / 16}rem` : "5rem")) ||
						undefined,
				};
			}
			if (bullet?.image) {
				return {
					...bullet,
					type: "image",
					image: bullet.image?.image,
				};
			}
		});

	const createLeftContent = (item) => {
		let leftContent = <></>;
		switch (item.component) {
			case LEFT_CONTENT.DESCRIPTION:
				leftContent = createTextDescription(item);
				break;
			case LEFT_CONTENT.IMAGE:
			case LEFT_CONTENT.LEANER_IMAGE:
				leftContent = createImageContent(item);
				break;
			case LEFT_CONTENT.PRICE_CARD:
				leftContent = createPriceCard(item);
				break;
			default:
				break;
		}
		return leftContent;
	};
	const contentStart = 4;
	const contentSpan = isTwoColumnSectionContent ? 8 : 6;
	return (
		<SimplifiedListWrapper
			className={`simplified-list outer-margin-wrap ${
				isTwoColumnSectionContent ? "two-column-section" : ""
			}`}
			isLeftSection={!!leftSection?.component}
			isDarkBg={isDarkBg}
			isB2U={isB2U}
		>
			{eyebrowLabel && (
				<div className="eyebrow-label">
					<Text dark={isDarkBg} variant="label_M">
						{eyebrowLabel}
					</Text>
				</div>
			)}
			<Grid className="top-content">
				<Col start={contentStart} span={contentSpan} mdStart={1} mdSpan={6}>
					{title ? (
						<Text
							dark={isDarkBg}
							className="header-text"
							variant="headline_3"
							tag="h2"
						>
							{title}
						</Text>
					) : null}
					{description ? (
						<Text
							dark={isDarkBg}
							className="description-text rich-text-wrapper"
							tag="div"
							variant="paragraph_M"
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					) : null}
				</Col>
			</Grid>
			<Grid className="bottom-content">
				<Col
					start={2}
					span={4}
					mdStart={1}
					mdSpan={6}
					className="left-content-wrapper"
				>
					{leftSection?.map((item, index) => (
						<div
							key={`left-content-${index}`}
							className={`left-content ${item?.component}`}
						>
							{createLeftContent(item)}
						</div>
					))}
				</Col>
				<Col
					start={leftSection?.[0]?.component ? 7 : contentStart}
					span={leftSection?.[0]?.component ? 5 : contentSpan}
					mdStart={1}
					mdSpan={6}
					className="right-content"
				>
					{listItems?.length ? (
						<div>
							{listHeader ? (
								<Text
									dark={isDarkBg}
									className={`list-header ${
										!leftSection?.[0]?.component && !isB2U ? "center" : ""
									}`}
									variant="paragraph_M_bold"
								>
									{listHeader}
								</Text>
							) : null}
							<VerticalList
								separator
								listBulletType={listBulletType}
								iconConfig={checkMarkIconConfig}
								customBulletList={createCustomBulletList()}
								darkBg={isDarkBg}
								listItems={listItems.map(
									({ headline = "", description = {} }) => {
										const descriptionInHTML = richTextResolver(
											description,
											globalVariableWithURL,
										);
										return (
											<ListItemWrapper>
												{headline ? (
													<Text
														dark={isDarkBg}
														className="item-header"
														variant="headline_6"
														tag="h3"
													>
														{headline}
													</Text>
												) : null}
												{description ? (
													<Text
														dark={isDarkBg}
														tag="div"
														className="item-description rich-text-wrapper"
														variant="paragraph_S"
														dangerouslySetInnerHTML={{
															__html: descriptionInHTML,
														}}
													></Text>
												) : null}
											</ListItemWrapper>
										);
									},
								)}
								gap="24px"
							/>
							{starRatingWithLogo &&
							Object.keys(starRatingWithLogo).length > 0 ? (
								<div className="star-component-separator">
									<Separator
										darkBg={isDarkBg}
										color="var(--secondary-gray-medium)"
									/>
								</div>
							) : (
								<></>
							)}
							{bottomDescription ? (
								<Text
									dark={isDarkBg}
									className="bottom-description-text rich-text-wrapper"
									tag="div"
									variant="paragraph_M"
									dangerouslySetInnerHTML={{ __html: bottomDescription }}
								/>
							) : null}
							{getLogoSection(logoSection)}
							{starRatingWithLogo &&
								Object.keys(starRatingWithLogo).length > 0 && (
									<div className="star-component">
										<StarRatingWithLogo
											starRatingWithLogo={starRatingWithLogo}
											hasDarkBackground={isDarkBg}
										></StarRatingWithLogo>
									</div>
								)}
						</div>
					) : null}
				</Col>
			</Grid>
			{cta?.buttonText ? (
				<div className="bottom-cta">
					<Button
						{...cta}
						onClick={() =>
							handleCTAClick(cta, {
								page_section: pageSection,
								click_text: cta?.buttonText,
								redirect_to: cta?.link?.link,
							})
						}
						style={{ fontWeight: "var(--desktop-body-s-bold-fw)" }}
						type={cta?.type}
						buttonText={cta?.buttonText}
						disabled={cta?.disabled}
						darkBg={cta?.darkBg}
					/>
				</div>
			) : null}
		</SimplifiedListWrapper>
	);
};
export default SimplifiedList;
